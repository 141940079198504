/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

body {
  background-color: aliceblue;
  text-align: center;
  font-size: 16pt;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.container {
  display: table;
  height: 95vh;
  width: 95vw;
}

.question, .code-area {
  padding: 25px;
  display: table-cell;
  width: 50%;
}

.question {
  text-align: left;
  position: absolute;
  top: 0vh;
}

.code-area {
  border-left: 2px solid navy;
}

.input, #results {
  height: 60%;
}

.input {
  border: 1px solid gray;
  width: 90%;
  font-family: monospace;
  font-size: 12pt;
  padding: 10px;
}

button {
  width: 150px;
  height: 50px;
  cursor: pointer;
  background-color: lightgreen;
  color: white;
  font-weight: bolder;
}

#results {
}